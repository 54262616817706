import { createFeature, createReducer, on } from '@ngrx/store'
import { profileInitialState } from './profile.state'
import { clearProfileData, loadProfileData, loadProfileDataError, loadProfileDataSuccess } from './profile.actions'

const profileReducer = createReducer(
  profileInitialState,
  on(loadProfileData, (state) => ({
    ...state,
    loading: true
  })),
  on(loadProfileDataSuccess, (state, { data }) => ({
    ...state,
    loading: false,
    data
  })),
  on(loadProfileDataError, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(clearProfileData, () => profileInitialState)
)

export const profileFeature = createFeature({
  name: 'profile',
  reducer: profileReducer
})
