import { Profile } from '@shared/models'
import { createAction, props } from '@ngrx/store'

export const profileStoreKey = 'Profile'

export const LOAD_PROFILE_DATA = `[${ profileStoreKey }] Load profile data`
export const LOAD_PROFILE_DATA_SUCCESS = `[${ profileStoreKey }] Load profile data success`
export const LOAD_PROFILE_DATA_ERROR = `[${ profileStoreKey }] Load profile data error`

export const loadProfileData = createAction(LOAD_PROFILE_DATA)
export const loadProfileDataSuccess = createAction(LOAD_PROFILE_DATA_SUCCESS, props<{ data: Profile }>())
export const loadProfileDataError = createAction(LOAD_PROFILE_DATA_ERROR, props<{ error: string }>())

export const CLEAR_PROFILE_DATA = `[${ profileStoreKey }] Clear profile data`
export const clearProfileData = createAction(CLEAR_PROFILE_DATA)
