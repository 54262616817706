import { inject, Injectable } from '@angular/core'
import { BehaviorSubject, catchError, Observable, of, tap, throwError } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { BaseResponse } from '@shared/models'
import { Store } from '@ngrx/store'
import { loadProfileData, profileFeature } from '@stores/profile'

export interface UserAuthSignInResponseData {
  '2fa_required': boolean
  access_token: {
    token: string
    expires_at: string
  }
}

export type UserAuthSignInResponse = BaseResponse<UserAuthSignInResponseData>

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly http: HttpClient = inject(HttpClient)
  private readonly store: Store = inject(Store)

  /**
   * Auth status
   * @type {BehaviorSubject<boolean>}
   * @defaultValue false
   */
  authStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  /** Key to store auth token in local storage */
  private readonly TOKEN_KEY = 'auth_token'

  /**
   * Authentication process
   * @description This method should be called when user submit sign in form
   * @param {string} login User login
   * @param {string} password User password
   * @returns {Observable<any>}
   */
  signIn (login: string, password: string): Observable<any> {
    return this.http.post<UserAuthSignInResponse>('v1/account/auth/login', { uuid: login, password })
      .pipe(
        tap((response: UserAuthSignInResponse) => {
          const { access_token } = response.data || {}

          if (access_token && access_token.token) {
            this.setTokenIntoStorage(access_token.token)
            this.authStatus.next(true)

            return of({ success: true, message: 'Вы успешно вошли в систему' })
          }

          return of({ success: false, ...response })
        }),
        catchError(({ error }) => {
          return throwError({
            success: false,
            message: error?.message || 'В текущей момент невозможно войти в систему'
          })
        })
      )
  }

  /**
   * De-authentication process
   * @description This method should be called when user click log out button
   * @returns {void}
   */
  logOut (): Observable<any> {
    this.removeTokenFromStorage()
    this.authStatus.next(false)

    return of({ success: true, message: 'Вы успешно вышли из системы' })
  }

  /**
   * Check authentication status
   * @description This method should be called when application starts or in some cases when user navigates to some routes
   * @returns {boolean} Authentication status (from auth status behavior subject)
   */
  checkAuth (): boolean {
    const token = this.getTokenFromStorage()

    if (token) {
      this.authStatus.next(true)
    } else if (this.authStatus.getValue()) {
      this.authStatus.next(false)
    }

    return this.authStatus.getValue()
  }

  /**
   * Get token from storage
   * @returns {string | null}
   */
  getToken (): string | null {
    return this.getTokenFromStorage()
  }

  getUserData (): Observable<any> {
    return this.store.select(profileFeature.selectData)
  }

  loadUserData () {
    this.store.dispatch(loadProfileData())
  }

  private setTokenIntoStorage (token: string) {
    localStorage.setItem(this.TOKEN_KEY, token)
  }

  private getTokenFromStorage (): string | null {
    return localStorage.getItem(this.TOKEN_KEY)
  }

  private removeTokenFromStorage () {
    localStorage.removeItem(this.TOKEN_KEY)
  }
}
