import { Injectable } from '@angular/core'
// RxJS
import { exhaustMap, of, catchError, map } from 'rxjs'
// NgRx
import { Actions, createEffect, ofType } from '@ngrx/effects'
// Service
import { ProfileApiService } from '@services/api'
// Store
import { LOAD_PROFILE_DATA, LOAD_PROFILE_DATA_SUCCESS, LOAD_PROFILE_DATA_ERROR } from './profile.actions'
// Data
import { DEFAULT_NOTIFICATION_ERROR_MESSAGE } from '@shared/consts'

@Injectable()
export class ProfileEffects {
  loadProfileData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_PROFILE_DATA),
      exhaustMap(() => this.api.getProfileData().pipe(
        map(({ data }) => (data ? {
          type: LOAD_PROFILE_DATA_SUCCESS,
          data: data
        } : {
          type: LOAD_PROFILE_DATA_ERROR,
          error: DEFAULT_NOTIFICATION_ERROR_MESSAGE
        })),
        catchError(({ error }) => of({
          type: LOAD_PROFILE_DATA_ERROR,
          error: error?.message || DEFAULT_NOTIFICATION_ERROR_MESSAGE
        }))
      ))
    )
  )

  constructor (
    private readonly actions$: Actions,
    private readonly api: ProfileApiService
  ) {
  }
}
