import { Profile } from '@shared/models'

export interface ProfileState {
  loading: boolean
  error: string
  data: Profile
}

export const profileInitialState: ProfileState = {
  loading: false,
  error: '',
  data: {} as Profile
}
